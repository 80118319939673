/* global SDKHelper:true */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { WithCmpVideoSettings } from './WithCmpVideoSettings';
import { WithVideoSDK } from './WithVideoSDK';
import { conf } from '../domain/services/configService';
import { domainService } from '../domain/services/domainService';
import { apiClientService } from '../domain/services/apiClientService';
import { adService } from '../domain/services/adService';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

const style = css`
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
`;

const SdkVideoPlayerInner = ({
  config,
  domain,
  country,
  sdk,
  content,
  containerId,
  onComplete
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const playerRef = useRef(null);

  const sdkContainerId =
    containerId || `sdk-video-player-${content?.id || ''}${config.id}`;

  useIntersectionObserver(playerRef, () => setIsVisible(true));

  const playerOptions = config;

  playerOptions.language = conf.langcode.toUpperCase();

  const targeting = adService.getTargeting(content);
  playerOptions.gam = {
    // Video Player auto populates the account id of the AdUnitPath, so let's remove it.
    iu: adService.getAdUnitPath(content).replace(/^\/\d+\//, '/'),
    vid: targeting.objid,
    tstadtar: targeting.tstAdTar,
    tag: targeting.tag,
    status: 'enabled'
  };
  playerOptions.adobe_enabled = true;
  playerOptions.releaseUrl = { policy: '131226695' };

  if (!config.template) {
    playerOptions.template = conf.VideoPlayerTemplate;
  }
  playerOptions.country = country;
  playerOptions.site_section_id = `www.${domain}`;

  if (conf.age_verification) {
    playerOptions.age_verification = conf.age_verification;
    playerOptions.autoplay = 'false';
  }
  if (playerOptions.slug && playerOptions.slug !== '') {
    playerOptions.apiUrl = `${domainService.getEndpointDomain()}/ajax/related_videos/related_videos_${
      playerOptions.slug
    }.json`;
  }

  if (typeof playerOptions.related_videos_plugin !== 'undefined') {
    window.fng_player_related_videos_plugin =
      playerOptions.related_videos_plugin;
  }
  window.fng_player_subtitles_plugin = true;
  window.fng_player_ga_plugin = false;
  // window.fng_player_gam_plugin = true;
  window.fng_player_agecheck_plugin = true;
  window.fng_player_unmute_plugin = true;
  window.fng_player_subtitle_plugin = true;
  playerOptions.google_enabled = ['br', 'mx'].includes(conf.siteCountryCode);

  useEffect(() => {
    let player;
    if (isVisible) {
      const checkShowAds = async guid => {
        const SDK = sdk;
        if (playerOptions.ads !== 'false') {
          const { response } = await apiClientService.getJson(
            '/ajax/video_no_ads/video_no_ads.json'
          );
          if (
            response.data.video.entities.some(video => {
              return video.guid === guid;
            })
          ) {
            playerOptions.ads = 'false';
          }
        }
        if (domainService.isStageCmsDomain(window.location.hostname)) {
          playerOptions.apiUrl = `${playerOptions.apiUrl}?auHash=1`;
        }
        player = new SDK(`#${sdkContainerId}`, playerOptions, [
          SDKHelper.setPlayerUrl,
          SDKHelper.setBrowserData
        ]);

        player.init().then(result => {
          if (onComplete) {
            result.addEventListener('dtci.contentEnded', e => {
              onComplete(e);
            });
          }
        });
      };
      checkShowAds(playerOptions.id);
    }
    return () => {
      if (player) {
        player.finalize();
        // Removes buggy window.onscroll event attached by player's JS script.
        window.onscroll = () => {};
      }
    };
  }, [playerOptions.id, isVisible]);

  return <div ref={playerRef} id={sdkContainerId} css={style} />;
};

SdkVideoPlayerInner.defaultProps = {
  domain: '',
  country: '',
  content: undefined,
  containerId: undefined,
  onComplete: undefined
};

SdkVideoPlayerInner.propTypes = {
  config: PropTypes.shape({
    template: PropTypes.string,
    autoplay: PropTypes.bool,
    id: PropTypes.string.isRequired,
    video_category: PropTypes.string,
    channel: PropTypes.string,
    slug: PropTypes.string,
    pg_rating: PropTypes.string,
    showTitle: PropTypes.string,
    video_publicationDate: PropTypes.string
  }).isRequired,
  sdk: PropTypes.func.isRequired,
  domain: PropTypes.string,
  country: PropTypes.string,
  content: PropTypes.objectOf(PropTypes.any),
  containerId: PropTypes.string,
  onComplete: PropTypes.func
};

export const SdkVideoPlayer = WithVideoSDK(
  WithCmpVideoSettings(SdkVideoPlayerInner)
);
