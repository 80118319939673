import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../domain/theme';
import { VideoCarousel } from '../VideoCarousel/VideoCarousel';
import { SdkVideoPlayer } from '../SdkVideoPlayer';
import { dateService } from '../../domain/services/dateService';
import {
  getPrimaryTaxonomySlug,
  mapContentToCardData,
  removeTaxonomyElementsFromCardData
} from '../../domain/utils/contentUtils';
import { stripTags } from '../../domain/utils/stringUtils';
import { smoothScrollToObject } from '../../domain/utils/domUtils';
import { ReadMore } from '../responsive/molecules/ReadMore';
import { imageResizerService } from '../../domain/services/imageResizerService';
import { breakpointService } from '../../domain/services/breakpointService';

const mapContentToVideoCardData = content => {
  const videoData = {
    guid: content.videoRef.entity.guid || content.videoRef.entity.mpxGuid
  };
  if (content.videoRef.entity.show?.[0]?.entity) {
    videoData.show = content.videoRef.entity.show[0].entity;
  }
  videoData.deck =
    content.promoSummary?.value &&
    stripTags(content.promoSummary?.value).trim();
  return videoData;
};

export const VideoPlaylistContent = ({
  videos,
  backgroundColor,
  hideVideoTitleAndDescriptions,
  carouselVerticalPositioning,
  isStandalone,
  isAboveTheFold,
  className
}) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const videoPlaylistWrapperRef = useRef(null);

  const styles = {
    carousel: css`
      background-color: ${backgroundColor};
      width: 100%;
      position: relative;
      margin-bottom: ${theme.spacing('xl')};
      padding: ${theme.spacing.parse('$xs 0')};
      ${theme.mq.small_desktop} {
        padding: 0.7rem;
      }
    `,
    carouselInner: css`
      margin: 0 auto;
      max-width: 1600px;
    `,
    videoWrapper: css`
      width: 100%;
      aspect-ratio: 16 / 9;
      padding: ${theme.spacing.parse('0 $s $s')};
      ${theme.mq.small_desktop} {
        padding: ${theme.spacing.parse('0 $base $base')};
      }
    `,
    copy: css`
      font-size: ${theme.font.size('s')};
      ${theme.font.family('regularWeb')};
      padding: ${theme.spacing.parse('0 $base')};
      margin-bottom: ${theme.spacing('base')};
      p {
        color: ${backgroundColor === 'white' ? `#000` : `#fff`};
        margin-bottom: 0;
      }
    `,
    copyTitle: css`
      ${theme.font.family('boldWeb')};
      text-transform: uppercase;
    `
  };

  const handleLoad = () => {
    const url = new URL(document.location);
    const queryVideoGuid = url.searchParams.get('video');
    if (queryVideoGuid) {
      const videoGuid = decodeURIComponent(queryVideoGuid.replace(/\+/g, ' '));
      const currentVideoIndex = videos.findIndex(video => {
        return (
          (video.entity.videoRef?.entity?.guid ||
            video.entity.videoRef?.entity?.mpxGuid) === videoGuid
        );
      });
      setActiveVideoIndex(currentVideoIndex);
      smoothScrollToObject(videoPlaylistWrapperRef);
    } else {
      setActiveVideoIndex(0);
    }
  };

  const updateQueryStringParameters = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({}, '', url.href);
  };

  const handlePlaylistItemClick = index => {
    setActiveVideoIndex(index);
    updateQueryStringParameters(
      'video',
      videos[index].entity.videoRef.entity.guid ||
        videos[index].entity.videoRef.entity.mpxGuid
    );
  };

  useEffect(() => {
    handleLoad();
  }, []);

  const pubTimestamp =
    videos[activeVideoIndex].entity.publishDate?.timestamp ||
    videos[activeVideoIndex].entity.created;
  const date = new Date(pubTimestamp * 1000);

  const videoData = removeTaxonomyElementsFromCardData(
    mapContentToCardData(videos[activeVideoIndex].entity)
  );
  videoData.video = mapContentToVideoCardData(videos[activeVideoIndex].entity);
  const videoThumb =
    videos[activeVideoIndex].entity.imageEntity &&
    videos[activeVideoIndex].entity.imageEntity.mediaImage
      ? imageResizerService.getStyleUrl(
          videos[activeVideoIndex].entity.imageEntity.mediaImage.url,
          breakpointService.getLargestBreakpoint().width,
          null,
          {
            position: videos[activeVideoIndex].entity.imageEntity.focalPoint
          }
        )
      : '';
  const videoPlayerConfig = {
    autoplay: true,
    id: videoData.video.guid,
    video_category: videos[0].entity.primaryTaxonomy?.entity?.name,
    slug: getPrimaryTaxonomySlug(videos[0].entity),
    channel: videoData.video.show?.channel,
    pg_rating: videoData.video.show?.rating,
    showTitle: videoData.video.show?.title,
    video_publicationDate: dateService.format(date, 'd/m/Y'),
    // Standalone: a video playlist is inside its own article, not embedded by a paragraph.
    video_playlist: isStandalone ? 1 : 2,
    video_title: videos[activeVideoIndex].entity.title,
    video_thumb: videoThumb
  };

  const activeVideo = videos[activeVideoIndex].entity;
  const activeVideoTitle = activeVideo.promoTitle || activeVideo.title;
  const activeVideoDescription = stripTags(
    activeVideo.promoSummary?.value ||
      activeVideo.videoRef.entity.subTitle?.value
  );

  const playlistId = videos.map(video => video.entity.id).join('-');
  const containerId = `${playlistId}-${videoData.video.guid}`;

  const onComplete = () => {
    if (videos[activeVideoIndex + 1]) {
      handlePlaylistItemClick(activeVideoIndex + 1);
    } else {
      handlePlaylistItemClick(0);
    }
  };

  const videoCarousel = (
    <VideoCarousel
      items={videos}
      backgroundColor={backgroundColor}
      isAboveTheFold={isAboveTheFold}
      onClick={handlePlaylistItemClick}
      activeIndex={activeVideoIndex}
    />
  );
  return (
    <div
      css={styles.carousel}
      className={className}
      ref={videoPlaylistWrapperRef}
    >
      <div css={styles.carouselInner}>
        {carouselVerticalPositioning === 'up' ? videoCarousel : null}
        <div css={styles.videoWrapper}>
          <SdkVideoPlayer
            config={videoPlayerConfig}
            content={activeVideo}
            containerId={containerId}
            onComplete={onComplete}
          />
        </div>
        {hideVideoTitleAndDescriptions ? null : (
          <div css={styles.copy}>
            <p css={styles.copyTitle}>
              <strong>{activeVideoTitle}</strong>
            </p>
            {activeVideoDescription ? (
              <ReadMore text={activeVideoDescription} maxChars={180} />
            ) : null}
          </div>
        )}
        {carouselVerticalPositioning === 'up' ? null : videoCarousel}
      </div>
    </div>
  );
};

VideoPlaylistContent.defaultProps = {
  backgroundColor: 'black',
  hideVideoTitleAndDescriptions: false,
  carouselVerticalPositioning: 'up',
  isStandalone: false,
  isAboveTheFold: false,
  className: ''
};

VideoPlaylistContent.propTypes = {
  videos: PropTypes.any.isRequired,
  backgroundColor: PropTypes.string,
  hideVideoTitleAndDescriptions: PropTypes.bool,
  carouselVerticalPositioning: PropTypes.string,
  isStandalone: PropTypes.bool,
  isAboveTheFold: PropTypes.bool,
  className: PropTypes.string
};
